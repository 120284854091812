<template>
    <div class="activity_speaker_ppt_add">
        <el-card>
            <el-page-header content="" style="margin-bottom: 30px;" @back="goBack" />
            <el-form ref="form" :model="form" label-width="auto" :rules="rules">
                <el-form-item label="标题" prop="title">
                    <el-col :span="8">
                        <el-input v-model="form.title"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="描述" prop="desc">
                    <el-col :span="8">
                        <el-input v-model="form.desc"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="来源" prop="source">
                    <el-col :span="8">
                        <el-input v-model="form.source"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="行业" prop="businessId">
                    <el-select v-model="form.businessId" multiple multiple-limit="3">
                        <el-option
                            v-for="item in activityConfig.activityBusiness"
                            :label="item.value"
                            :value="item.key + ''"
                            :key="item.key"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否可以下载源文件" prop="vipDownload">
                    <el-select v-model="form.vipDownload" placeholder="请选择">
                        <el-option label="是" :value="1" />
                        <el-option label="否" :value="0" />
                    </el-select>
                    <el-tooltip class="item" effect="dark" content="仅会员可下载" placement="top">
                        <i class="el-icon-question" style="position: relative;margin-left: 12px;"></i>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="付费类型" prop="payType">
                    <el-select v-model="form.payType" @change="changeType">
                        <el-option
                            :label="item"
                            :value="+index"
                            v-for="(item, index) in payType"
                            :key="index"
                        ></el-option>
                    </el-select>
                    <el-checkbox
                        v-model="form.vipFree"
                        style="margin-left: 10px; position: relative;"
                        v-if="[3, 1, 2].includes(+form.payType)"
                    >
                        会员免费查看
                    </el-checkbox>
                </el-form-item>
                <el-form-item label="支付金额" v-if="form.payType != 0" prop="payAmount">
                    <el-col :span="8">
                        <el-select v-model="form.payAmount" v-if="form.payType == 3">
                            <el-option
                                :label="item.title"
                                :value="`${item.price}`"
                                :key="index"
                                v-for="(item, index) in reportConfig.reportPrice"
                            >
                            </el-option>
                        </el-select>
                        <el-input v-else v-model="form.payAmount" type="number"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="PDF" prop="pdfUrl">
                    <el-col :span="8">
                        <up-file
                            :action="{ action: 'activity' }"
                            tip="请上传PDF文件，文件大小60M以内"
                            v-model="form.pdfUrl"
                            accept=".pdf"
                        ></up-file>
                    </el-col>
                </el-form-item>
                <el-form-item label="主图" prop="banner">
                    <el-col :span="8">
                        <up-img :action="{ action: 'activity' }" :banner.sync="form.banner"></up-img>
                    </el-col>
                </el-form-item>
                <el-form-item label="内容" prop="content">
                    <Tiny v-model="form.content" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">{{ query.edit ? "修改" : "立即创建" }}</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import { payType, viewType } from "@/utils/dictionary.js";
import rules from "@/utils/rules.js";
import { forData } from "@/utils/methods.js";
export default {
    data() {
        return {
            form: {
                payType: 0,
                payAmount: "",
                vipDownload: 1,
            },
            payType,
            viewType,
            activityConfig: {},
            rules: rules.reqAll({
                payType: "请填写付费类型",
                payAmount: "请填写支付金额",
                businessId: "请选择行业",
                title: "请填写标题",
                desc: "请填写描述",
                banner: "请上传主图",
                source: "请填写来源",
                pdfUrl: "请上传PDF",
                viewType: "请选择查看方式",
                content: "请填写内容",
            }),
            query: {},
            reportConfig: {},
        };
    },
    created() {
        this.query = this.$route.query;
        this.getConfig();
        this.getForm();
        this.getReportConfig();
        sessionStorage.setItem("action", "activityEditor");
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        changeType(val) {
            if (val == 3) {
                this.form.payAmount = "";
            }
            if (val == 1) {
                this.form.vipFree = true;
            } else {
                this.form.vipFree = false;
            }
        },
        change(val) {
            this.form.content = val;
        },
        getReportConfig() {
            this.$http.get("/admin/ReportCategory/getList").then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.reportConfig = res.data;
                }
            });
        },
        async getForm() {
            if (!this.query.edit) return;
            let { data: res } = await this.$http.get("/admin/ActivityPpt/getById", {
                params: {
                    id: this.query.edit,
                },
            });
            let form = forData(res.data, ["businessId"]);
            this.form = {
                ...form,
                vipFree: form.vipFree == 1 ? true : false,
            };
        },
        async getConfig() {
            let { data: res } = await this.$http.get("/admin/Common/getConfigDictionaries", {
                params: {
                    config: "activity",
                },
            });
            if (res.errorCode == 200) {
                this.activityConfig = res.data;
            }
        },
        async onSubmit() {
            this.$refs.form.validate();
            const form = {
                ...this.form,
                activityId: this.$route.query.id,
                businessId: this.form.businessId.toString(),
                vipFree: this.form.vipFree ? 1 : 0,
            };
            if (this.query.edit) {
                form.id = this.query.edit;
                var { data: res } = await this.$http.post("/admin/ActivityPpt/edit", form);
            } else {
                var { data: res } = await this.$http.post("/admin/ActivityPpt/add", form);
            }
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$router.go(-1);
            }
        },
    },
    watch: {},
};
</script>

<style lang="less" scoped></style>
